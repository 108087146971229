<template>
  <transition name="fade">
    <div v-if="show" class="loader" :style="`background-color: ${colorBG};`">
      <div class="loader" :style="`background-color: ${colorBG};`">
        <div class="wrap-logo">
          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 775.31 270.26">

            <g id="Capa_1" data-name="Capa 1">
              <g>
                <path class="cls-1"
                  d="M269.04,95.09c-26.54,0-50.38,11.86-66.45,30.58l-.03-.02-70.1,84.58c-5.64,6.61-11.99,11.58-18.84,14.74-5.68,2.62-11.03,3.8-17.39,3.8-12.88,0-24.82-5.18-33.63-14.57-8.81-9.37-13.2-21.66-12.38-34.58,1.44-22.67,19.58-41.12,42.21-42.9,13.56-1.07,26.83,3.8,36.38,13.36l29.34-29.34c-18.14-18.16-43.28-27.41-68.99-25.38-43.07,3.41-77.61,38.5-80.34,81.65-1.56,24.47,6.79,47.78,23.53,65.6,16.5,17.57,39.77,27.65,63.88,27.65,12.3,0,23.68-2.48,34.78-7.6,12.39-5.74,23.55-14.35,33.14-25.64l21.88-26.39c11.68,34.63,44.49,59.63,83.01,59.63,48.29,0,87.59-39.29,87.59-87.59s-39.3-87.58-87.59-87.58ZM269.04,228.78c-25.42,0-46.1-20.69-46.1-46.11,0-10.97,3.84-21.04,10.28-28.97.3-.39.64-.79.98-1.18,8.44-9.76,20.93-15.95,34.84-15.95,25.42,0,46.1,20.69,46.1,46.1s-20.67,46.11-46.1,46.11Z" />
                <rect class="cls-1" x="365.47" width="41.49" height="270.12" />
                <path class="cls-1"
                  d="M549.66,92.18v16.01c-13.68-8.49-29.59-13.11-46.1-13.11-48.31,0-87.59,39.29-87.59,87.58s39.29,87.59,87.59,87.59c16.41,0,32.37-4.63,46.1-13.14v13h41.48V92.18h-41.48ZM537.75,213.58c-8.73,9.66-21.19,15.19-34.19,15.19-25.42,0-46.1-20.69-46.1-46.11s20.67-46.1,46.1-46.1c13.64,0,26.5,6.01,35.29,16.45,6.96,8.28,10.81,18.81,10.81,29.65,0,11.46-4.24,22.44-11.91,30.92Z" />
                <path class="cls-1"
                  d="M687.72,95.09c-16.9,0-32.7,4.81-46.1,13.15V0h-41.48v270.12h41.48v-13.02c13.39,8.34,29.19,13.15,46.1,13.15,48.31,0,87.59-39.29,87.59-87.59s-39.29-87.58-87.59-87.58ZM687.72,228.78c-25.41,0-46.1-20.69-46.1-46.11s20.69-46.1,46.1-46.1,46.1,20.69,46.1,46.1-20.67,46.11-46.1,46.11Z" />
                <circle class="cls-1" cx="20.75" cy="84.37" r="20.75" />
              </g>
            </g>
          </svg>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
// import Lottie from "./Lottie";

export default {
  name: "Preloader",
  components: {
    // lottie: Lottie,
  },
  data() {
    return {
      colorBG: "#1E2428",
      color: "",
      isMenu: true,
      anim: null,
      show: true,
      show_lottie: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.show_lottie = true;
        this.showLoader();
      }, 500);
    });
  },
  methods: {
    showLoader() {
      window.onload = setTimeout(() => {
        this.show = false;
      }, 3000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@import "@/_responsive";

.st0 {
  fill: #fff;
}



.logo-svg {
  width: 200px;
  height: 200px;
}

.loader {
  width: 100vw;
  height: 100vh;
  z-index: 10000 !important;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  position: fixed;
  flex-direction: column;

  .cls-1 {
    fill: #fdfdfd;
    stroke-width: 0px;
  }

  svg {
    width: 300px;

    @include responsive(mobile) {
      width: 180px;
 
    }
  }

  img {
    width: 400px;

    @include responsive(mobile) {
      width: 300px;
    }
  }

  .lottie-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
  }
}

@include responsive(mobile) {
  .lottie-content {
    width: 75% !important;
  }
}

@include responsive(desktop) {
  .lottie-content {
    width: 200px !important;
    // height: 100px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.by-ocho {
  position: absolute;
  bottom: 15vh;
}

.st0 {
  fill: none;
  stroke: #000000d9;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray: 1000;
  animation: circle_stroke 2s ease-in forwards;
}

@keyframes circle_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #000;
  }
}

@keyframes red_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #ea2228;
  }
}

@keyframes azul_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #59c6d5;
  }
}

@keyframes naranjas_stroke {
  0% {
    stroke-dashoffset: 2000;
    fill: rgba(255, 255, 255, 0);
  }

  40% {
    fill: rgba(255, 255, 255, 0);
  }

  100% {
    stroke-dashoffset: 0;
    fill: #f69224;
  }
}

.wrap-logo {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 250px;
  }
}



.disclamer {
  position: absolute;
  bottom: 10vh;
  display: flex;
  gap: 10px;

  img {
    width: 150px;
  }
}
</style>
