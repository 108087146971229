<template>
  <div class="index" :style="{ backgroundImage: `url(${user.backgroundLink})` }">
    <div class="blur"></div>
    <v-container>

      <div class="wrap-phton" v-if="user">
        <v-avatar size="100">
          <v-img :src="user.photoUrl" alt="logo"></v-img>
        </v-avatar>
        <div class="wrap-name" style="display: flex; align-items: center;">
          <h2>{{ user.firstName }} {{ user.lastName }}
          </h2>
          <svg style="margin-left: 5px; width: 20px; height: 20px;" xmlns="http://www.w3.org/2000/svg" version="1.1"
            viewBox="0 0 174.3 174.3">

            <!-- Generator: Adobe Illustrator 28.7.1, SVG Export Plug-In . SVG Version: 1.2.0 Build 142)  -->
            <g>
              <g id="Layer_1">
                <path class="cls-1"
                  d="M174.3,87.1l-14.2-14.2,7.8-18.5-19.2-8.2v-20.8h-20l-7.5-18.6-19.3,7.8L87.1,0l-14.2,14.2-18.4-7.8-8.1,19.2h-20.9v20l-18.6,7.5,7.8,19.3L0,87.1l14.2,14.2-7.8,18.4,19.2,8.2v20.8h20l7.5,18.6,19.3-7.8,14.7,14.7,14.2-14.2,18.5,7.8,8.1-19.2h20.9v-20l18.6-7.5-7.8-19.3,14.8-14.7ZM126.5,70.7h0s-44.6,44.6-44.6,44.6c-3.3,3.3-8.6,3.3-11.8,0l-22.3-22.3c-3.3-3.3-3.3-8.6,0-11.8,3.3-3.2,8.6-3.3,11.8,0l16.4,16.4,38.7-38.7c3.3-3.3,8.6-3.3,11.8,0,3.2,3.3,3.3,8.6,0,11.8Z" />
              </g>
            </g>
          </svg>
        </div>
   
      <div class="list-btn-redes mt-6"  v-if="user.redesSociales">
        
        <v-btn v-for="item in user.redesSociales.filter(item => item.show)" :key="item.id" style="color: #fff;" color="primary"   :href="item.link" target="_blank" >
          <v-icon class="mr-2" size="20" >{{ item.icon }} </v-icon>
          {{ item.name }}
        </v-btn>
     
      </div>

<v-btn target="_blank" class="power" height="30" color="transparent" elevation="0" href="https://colab.hn/" >
  <svg xmlns="http://www.w3.org/2000/svg" style="width: 100px; height: 30px;"  version="1.1" viewBox="0 0 214.6 82.8">
  <g>
    <g id="Layer_1">
      <g>
        <g id="Capa_1">
          <g>
            <path class="powere-fill" d="M74.5,34.3c-7.3,0-13.9,3.3-18.4,8.5h0s-19.4,23.4-19.4,23.4c-1.6,1.8-3.3,3.2-5.2,4.1-1.6.7-3.1,1.1-4.8,1.1-3.6,0-6.9-1.4-9.3-4-2.4-2.6-3.7-6-3.4-9.6.4-6.3,5.4-11.4,11.7-11.9,3.8-.3,7.4,1.1,10.1,3.7l8.1-8.1c-5-5-12-7.6-19.1-7-11.9.9-21.5,10.7-22.2,22.6-.4,6.8,1.9,13.2,6.5,18.2,4.6,4.9,11,7.7,17.7,7.7s6.6-.7,9.6-2.1c3.4-1.6,6.5-4,9.2-7.1l6.1-7.3c3.2,9.6,12.3,16.5,23,16.5s24.2-10.9,24.2-24.2-10.9-24.2-24.2-24.2h0ZM74.5,71.3c-7,0-12.8-5.7-12.8-12.8s1.1-5.8,2.8-8c0-.1.2-.2.3-.3,2.3-2.7,5.8-4.4,9.6-4.4,7,0,12.8,5.7,12.8,12.8s-5.7,12.8-12.8,12.8h0Z"/>
            <rect class="powere-fill" x="101.1" y="8" width="11.5" height="74.8"/>
            <path class="powere-fill" d="M152.1,33.5v4.4c-3.8-2.3-8.2-3.6-12.8-3.6-13.4,0-24.2,10.9-24.2,24.2s10.9,24.2,24.2,24.2,9-1.3,12.8-3.6v3.6h11.5v-49.2h-11.5ZM148.8,67.1c-2.4,2.7-5.9,4.2-9.5,4.2-7,0-12.8-5.7-12.8-12.8s5.7-12.8,12.8-12.8,7.3,1.7,9.8,4.6c1.9,2.3,3,5.2,3,8.2s-1.2,6.2-3.3,8.6h0Z"/>
            <path class="powere-fill" d="M190.3,34.3c-4.7,0-9,1.3-12.8,3.6V8h-11.5v74.8h11.5v-3.6c3.7,2.3,8.1,3.6,12.8,3.6,13.4,0,24.2-10.9,24.2-24.2s-10.9-24.2-24.2-24.2h0ZM190.3,71.3c-7,0-12.8-5.7-12.8-12.8s5.7-12.8,12.8-12.8,12.8,5.7,12.8,12.8-5.7,12.8-12.8,12.8h0Z"/>
            <circle class="powere-fill" cx="5.7" cy="31.4" r="5.7"/>
          </g>
        </g>
        <g>
          <path class="powere-fill" d="M1.1,3.6h2.1v1h.2c.4-.7,1.3-1.2,2.4-1.2,2,0,3.6,1.7,3.6,4.2s-1.6,4.2-3.6,4.2-1.9-.5-2.3-1.1h0v4.7H1.1V3.6ZM5.2,9.8c1.1,0,1.8-.8,1.8-2.1s-.8-2.1-1.8-2.1-1.8.7-1.8,1.7v1c0,.9.8,1.7,1.8,1.7Z"/>
          <path class="powere-fill" d="M10.7,7.7c0-2.5,1.8-4.2,4.2-4.2s4.2,1.7,4.2,4.2-1.8,4.2-4.2,4.2-4.2-1.7-4.2-4.2ZM14.9,9.8c1.1,0,1.9-.8,1.9-2.2s-.8-2.1-1.9-2.1-1.9.8-1.9,2.1.8,2.2,1.9,2.2Z"/>
          <path class="powere-fill" d="M19.8,3.6h2.4l1.4,5h0l1.6-5h2.1l1.6,5h0l1.4-5h2.4l-2.5,8.1h-2.4l-1.6-5.1h0l-1.6,5.1h-2.5l-2.5-8.1Z"/>
          <path class="powere-fill" d="M33.6,7.7c0-2.5,1.8-4.3,4.2-4.3s4,1.5,4,4,0,.5,0,.8h-5.8c.2,1.2.9,1.7,2,1.7s1.4-.3,1.6-.9l2.1.5c-.5,1.5-2,2.4-3.8,2.4s-4.2-1.7-4.2-4.2ZM39.4,6.7c-.2-1-.8-1.4-1.6-1.4s-1.6.5-1.8,1.4h3.5Z"/>
          <path class="powere-fill" d="M43.4,3.6h2.1v1.2h.2c.4-.9,1.2-1.4,2.2-1.4s.5,0,.7.1v2.2c-.3,0-.5,0-.7,0-1.5,0-2.2.8-2.2,2.3v3.6h-2.3V3.6Z"/>
          <path class="powere-fill" d="M49.4,7.7c0-2.5,1.8-4.3,4.2-4.3s4,1.5,4,4,0,.5,0,.8h-5.8c.2,1.2.9,1.7,2,1.7s1.4-.3,1.6-.9l2.1.5c-.5,1.5-2,2.4-3.8,2.4s-4.2-1.7-4.2-4.2ZM55.3,6.7c-.2-1-.8-1.4-1.6-1.4s-1.6.5-1.8,1.4h3.5Z"/>
          <path class="powere-fill" d="M58.9,7.7c0-2.6,1.6-4.2,3.6-4.2s1.9.5,2.3,1.1h0V0h2.3v11.7h-2.1v-1h-.2c-.4.7-1.4,1.2-2.4,1.2-2,0-3.6-1.7-3.6-4.2ZM63.1,9.8c1,0,1.8-.7,1.8-1.7v-1c0-.9-.8-1.7-1.8-1.7s-1.8.8-1.8,2.1.8,2.1,1.8,2.1Z"/>
          <path class="powere-fill" d="M75.3,10.7h0v1h-2.2V0h2.3v4.5h0c.4-.6,1.2-1.1,2.3-1.1,2,0,3.6,1.7,3.6,4.2s-1.6,4.2-3.6,4.2-2-.5-2.4-1.2ZM77.2,9.8c1.1,0,1.8-.8,1.8-2.1s-.8-2.1-1.8-2.1-1.8.7-1.8,1.7v1c0,.9.8,1.7,1.8,1.7Z"/>
          <path class="powere-fill" d="M85,11.8l-3-8.2h2.5l1.7,5.2h0l1.7-5.2h2.5l-4.4,11.8h-2.5l1.3-3.7Z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
</v-btn>
  


    </div>


    </v-container>
  </div>
</template>

<script setup>
import { db } from '@/firebase';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
const loading = ref(false);
const user = ref({});
const codeUser = ref(route.params.code);

onMounted(async () => {

  await db.collection('colabLinkUsuarios').where('codeLinkColab', '==', codeUser.value).onSnapshot((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      console.log(codeUser.value);
      console.log(doc.data());
      user.value = doc.data();
    });
  });

});




</script>

<style scoped>
.cls-1 {
  fill: #0068ff;
}

.index {
  background-color: #000000;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;

  

}

.blur {
  filter: blur(15px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
 
}
.powere-fill {
  fill: #fff;
}

.wrap-phton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.list-btn-redes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 100px;
}

.wrap-phton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 20px;
  min-height: 70vh;
  max-height: auto;
  color: #fff;
  backdrop-filter: blur(10px);
  background: rgba(46, 46, 46, 0.264);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.311);
  padding-top: 40px;
  padding-bottom: 40px;
  border: 1px solid #ffffff26;
}

.power {
  width: 100px;
  z-index: 1;
  position: absolute;
  bottom: 40px;
}
</style>