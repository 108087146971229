import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

let env = process.env.NODE_ENV;

var devConfig = {
  apiKey: "AIzaSyDbrMMJ0uZRtabNVFVWa_3NpAZtsvPEa6I",
  authDomain: "colab-lat.firebaseapp.com",
  projectId: "colab-lat",
  storageBucket: "colab-lat.appspot.com",
  messagingSenderId: "754166396572",
  appId: "1:754166396572:web:bb5d046e57388d47381f59"
};

var prodConfig = {
  apiKey: "AIzaSyDbrMMJ0uZRtabNVFVWa_3NpAZtsvPEa6I",
  authDomain: "colab-lat.firebaseapp.com",
  projectId: "colab-lat",
  storageBucket: "colab-lat.appspot.com",
  messagingSenderId: "754166396572",
  appId: "1:754166396572:web:bb5d046e57388d47381f59"
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);

// if (env == "development") {
// firebase.functions().useFunctionsEmulator("http://localhost:5001");
// }

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
