// Styles
import '@mdi/font/css/materialdesignicons.css'
// import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files
import 'vuetify/styles'
import { aliases, fa } from 'vuetify/iconsets/fa'


// Vuetify
import { createVuetify } from 'vuetify'
const myCustomLightTheme = {
  dark: true,
  colors: {
    background: '#1E2428',
    surface: '#1E2428',
    'text-primary': '#1E2428',
    'surface-bright': '#F9F9F9',
    'surface-light': '#f2f2f2',
    'surface-variant': '#d9d9d9',
    'on-surface-variant': '#EEEEEE',
    primary: '#1E2428',
    'primary-darken-1': '#1E2428',
    secondary: '#48A9A6',
    'secondary-darken-1': '#018786',
    error: '#FF4F4F',
    border:'#EEEEEE',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#ffc200',
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000',
  }
}
const myCustomDarkTheme = {
  dark: true,

  colors: {
    background: '#1E2428',
    surface: '#273035',
    'text-primary': '#cfcfcf',
    'surface-bright': '#37424a',
    'surface-light': '#37424a',
    'surface-variant': '#485660',
    'on-surface-variant': '#EEEEEE',
    primary: '#F9F9F9',
    border:'#EEEEEE',
    'primary-darken-1': '#1E2428',
    secondary: '#48A9A6',
    'secondary-darken-1': '#018786',
    error: '#FF4F4F',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#ffc200',
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000',
  }
}


export default createVuetify({
  defaults: {
    global: {
      ripple: false,
    },
    VSheet: {
      elevation: 0,
    },
    VCard: {
      style: '    border-radius: 19px !important;',
   
    },
    VBtn: {
      style: 'border-radius:12px !important; ',
    },
    VImg: {
      style: 'border-radius:12px !important;',
    },
    VTextField:{ variant: 'outlined', color: 'primary', height: '20px' }

  },
  theme: {
    dark:true,

    defaultTheme:  'light', // Changed from 'light' to 'dark'
    themes: {
      light: myCustomLightTheme,
      dark:myCustomDarkTheme,

    },
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
})
